<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Logger">
      <b-row>
        <!-- filter -->
        <b-col cols="12">
          <b-row>
            <b-col md="3">
              <b-form-group label="Model Type" label-for="Model Type">
                <v-select
                  id="model_type"
                  v-model="modelTypeSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="modelTypes"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Model ID" label-for="Model ID">
                <b-form-input v-model="modelID" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Admin" label-for="Admin">
                <v-select
                  @input="setAdmin"
                  id="admin"
                  v-model="adminSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="adminTypes"
                  label="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Vendor" label-for="Vendor">
                <v-select
                  id="vendor"
                  @input="setVendor"
                  v-model="vendorSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="vendorTypes"
                  label="text"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="SearchData()">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5;"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="tagTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            @row-clicked="activityLoggerQuickView"
          >
            <template #cell(admin)="items">
              <b-badge variant="primary" v-if="items.item.admin != null">{{
                items.item.admin.email
              }}</b-badge>
              <b-badge variant="warning" v-else-if="items.item.vendor != null">
                {{items.item.vendor.email}}</b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>

       <!-- Order Details in table for variant -->
      <b-modal
      id="logger-quick-view-modal"
      hide-footer
      ref="modal"
      centered
      title="Logger Details"
      size="lg"
    >

  
    <b-row v-if="loggerModal">
        <b-col md="6">
          <p class="text-primary h4 mt-1">
            Model Type: <span class="h5">{{ loggerModal.model_type }}</span>
          </p>
      
          
          <p class="text-primary h4 mt-1">
            Model id:
            <span class="h5">{{ loggerModal.model_id  }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Action :
            <span class="h5">{{ loggerModal.action  }}</span>
          </p>
          
        </b-col>
        <b-col md="6">
          <!-- <p class="text-primary h4 mt-1">
            Code: <span class="h5">{{ loggerModal.code }}</span>
          </p> -->
          <p class="text-primary h4 mt-1" v-if="loggerModal.admin">
            Admin:
            <b-badge variant="primary">{{ loggerModal.admin.firstname  }}{{ loggerModal.admin.lastname  }}</b-badge>
          </p>
          <p class="text-primary h4 mt-1" v-if="loggerModal.vendor">
            Vendor:
            <b-badge variant="info">{{ loggerModal.vendor.firstname  }}{{ loggerModal.vendor.lastname  }}</b-badge>
          </p>
          
        </b-col>
    
        
      </b-row>
    
    <b-row v-if="loggerModal.logger_details" class="my-2">
        <b-col md="12">
          <p class="text-secondary h4 mt-1">Logger Details</p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Attribute Name</th>
                <th scope="col">Old Value</th>
                <th scope="col">New Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in loggerModal.logger_details" :key="index">
                <td dir="rtl">{{ item.attribute }}</td>
              
                
                <td>{{ item.old_value }}</td>
                <td>{{ item.new_value }}</td>
                
                
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      
    </b-modal>
  </div>
</template>

<script>

export default {

  data() {
    return {
      adminSelect: null,
      adminTypes: [],
      vendorSelect: null,
      vendorTypes: [],
      modelTypes: [
        'products',
        'variants',
        'orders',
        'admins',
        'items',
        'offers',
        'vendors',
        'values',
        'reports',
        'refunds',
        'problems',
        'payouts',
      ],
      modelTypeSelect: null,
      modelID: null,
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      isBusy: false,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'admin',
          label: 'admin / vendor',
        },
        {
          key: 'model_type',
          label: 'model type',
        },
        {
          key: 'model_id',
          label: 'model id',
        },
        {
          key: 'action',
          label: 'action',
        },
        {
          key: 'created_at',
          label: 'created at',
        },
        {
          key: 'updated_at',
          label: 'updated at',
        },
        
      ],
      items: [],
      exportItems: [],
      loggerModal:[],
    }
  },
  created() {
    // request terms index
    this.fetchData()
    axios
      .get('admins')
      .then((result) => {
        const data = result.data.data
        for (let index in data) {
          this.adminTypes.push({
            text: data[index].email,
            value: data[index].id.toString(),
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    axios
      .get('vendors')
      .then((result) => {
        const data = result.data.data
        for (let index in data) {
          this.vendorTypes.push({
            text: data[index].storename,
            value: data[index].id.toString(),
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    
    // sortDirection: function () {
    //   this.sortingTable()
    // },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
    modelTypeSelect: function () {
      this.modelID = null
      this.fetchData()
    },
    modelID: function () {
      this.fetchData()
    },
  },
  methods: {
    
    activityLoggerQuickView(item, index, event) {
      console.log(item)
      this.$bvModal.show('logger-quick-view-modal')
      this.loggerModal = item
      
    },
    setAdmin() {
      this.vendorSelect = null
      this.fetchData()
    },
    setVendor() {
      this.adminSelect = null
      this.fetchData()
    },
    // fetch data of terms
    fetchData() {
      var data = {
        model_type: this.modelTypeSelect != null ? this.modelTypeSelect : '',
        model_id: this.modelID != null ? this.modelID : '',
        admin_id: this.adminSelect != null ? this.adminSelect.value : '',
        vendor_id: this.vendorSelect != null ? this.vendorSelect.value : '',
      }
      if (data.model_type == null || data.model_type == '') {
        delete data.model_type
      }
      if (data.model_id == null || data.model_id == '') {
        delete data.model_id
      }
      if (data.admin_id == null || data.admin_id == '') {
        delete data.admin_id
      }
      if (data.vendor_id == null || data.vendor_id == '') {
        delete data.vendor_id
      }
      this.showDismissibleAlert = false
      // request category index API
      var url = null
      if (this.filter == '') {
        url =
          'activity-logs/' +
          this.perPage.toString() +
          '?page=' +
          this.currentPage
      } else {
        url =
          'activity-logs/' +
          this.perPage.toString() +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.post(url, data)
      return promise
        .then((result) => {
          
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items =  result.data.data.data
        
          
          this.exportItems = this.items
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    searchData() {
      this.currentPage = 1
   
      this.fetchData()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].language,
        ]
      }
      doc.autoTable({
        head: [['ID', 'Name', 'language']],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

